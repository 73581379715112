export default defineNuxtRouteMiddleware(async () => {
  const { currentlyInsideProclaimMaintenanceWindow } = useEnvironment()

  if (currentlyInsideProclaimMaintenanceWindow.value) {
    throw showError({
      statusCode: 503,
      statusMessage:
        'To maintain safety of your documents, a routine backup takes place overnight. Document viewing will be unavailable between 11pm and 7am.',
    })
  }
})
